<script>
    import PageHeader from "@/components/structure/page-header";
    import appConfig from "@/app.config";
    import Challenges from "../../components/Challenges";

    export default {
        page: {
            title: "Training",
            meta: [{name: "description", content: appConfig.description}]
        },
        name: 'Training',
        components: {
          Challenges, PageHeader},

    };
</script>
<template>
    <div>
        <PageHeader :title="$t('competition.title')"/>
      <div class="row">
        <div class="col-xl-10 m-auto">
        <challenges type="training" state=""/>
        </div>
      </div>

    </div>
</template>
<style scoped>

</style>